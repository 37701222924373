import React, {useState} from "react"
import parse from 'html-react-parser';
import Image from '../ResuableImageWrapper';
import VisibilitySensor from 'react-visibility-sensor';
import "../../../sass/components/reuseable_components/_image_section.scss";

const BasicFullWidthImage = (data, {isInView}) => {
  
  let classList = [
    'image-section', 
    'clearfix'
  ]; 

  const removeBottomMargin = data.removeBottomMargin;
  const notFullWidth = data.notFullWidth;

  let maxWidth = data?.image?.localFile?.childImageSharp?.fluid?.sizes;
  maxWidth = maxWidth?.substring(maxWidth.lastIndexOf(" "));

  if(removeBottomMargin === true){
    classList.push('no-margin');
  }
  if(notFullWidth === true){
    classList.push('not-full-width');
  }

    
  
  const [isInview, setIsInview] = useState(false);

  function inviewOnChange (isVisible) {
    setIsInview(isVisible)
  }
  
  return (
    <VisibilitySensor partialVisibility={true} offset={{bottom:120}} active={!isInview}  onChange={inviewOnChange}>
    {({isVisible}) =>
      <section className={`${classList.join(" ")} ${isInview ? 'is_inview' : ''}`}  data-bg-shape="show_shape">
        {data?.image?.localFile?.childImageSharp?.fluid ? '' : 'image not found'}
        <Image image={data?.image?.localFile?.childImageSharp?.fluid} alt={data.image} maxWidth={notFullWidth ? maxWidth : ""}/>
        {data.caption ? 
          <div className="label">{parse(data.caption)}</div> 
          : '' 
        }
      </section>
    }
  </VisibilitySensor>
  )
}

export default BasicFullWidthImage

